import type { StoryblokVersion, Story } from "~/types/storyblok-generic";
import type { LocationQueryValue } from "#vue-router";

export default async function useStoryblokRouter<T extends Record<string, any> = Story>(
	routePath?: string
): Promise<{
	slug: ComputedRef<string>;
	story: Ref<T | null>;
	error: Ref<Error | undefined>;
	version: Ref<StoryblokVersion>;
	preview: string | false | LocationQueryValue[];
	refresh: () => void;
}> {
	const { hook } = useNuxtApp();
	const storyblokApi = useStoryblokApi();
	const route = useRoute();
	const preview = route.query?._storyblok || false;
	const storyblokEnv = useRuntimeConfig()?.public?.storyblokEnv ?? "production";
	const version = ref<StoryblokVersion>(storyblokEnv !== "production" ? "draft" : "published");
	const slug = computed<string>(() => {
		if (routePath) return routePath;
		return route.path === "/" ? "/home" : route.path;
	});
	const story = useState<T>(`storyblok:${slug.value}`);
	const error = ref<Error>();

	onMounted(() => {
		if (preview && story.value && story.value.id) {
			useStoryblokBridge(story.value.id, (data) => (story.value = data as unknown as T));
		}
	});

	if (process.env.NODE_ENV === "production" && slug.value === "error") {
		throw createError({
			statusCode: 404,
			message: "Page not found",
			fatal: true
		});
	}

	if (!story.value) {
		story.value = (await fetchStoryblokData()) as T;
	}

	async function fetchStoryblokData(): Promise<T | undefined> {
		try {
			const { data } = await storyblokApi.get(`cdn/stories/${slug.value}`, {
				version: version.value,
				resolve_relations: ["blog-highlight.blogs", "blog-overview.blogs", "vacancy-carousel.vacancies"]
			});

			return data.story;
		} catch (err) {
			error.value = err as Error;
		}
	}

	async function refresh() {
		const sbStory = (await fetchStoryblokData()) as T;
		story.value = sbStory;
	}

	if (preview) {
		hook("page:finish", () => {
			refreshNuxtData();
		});
	}

	return {
		slug,
		version,
		error,
		story,
		refresh,
		preview
	};
}
